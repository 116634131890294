import Home from '../views/Home.vue'
import Dac7 from '../views/Dac7.vue'
const OrderDetails = () =>
  import(/* webpackChunkName: "orderDetails" */ '../views/OrderDetails.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      name: 'order history',
      group: 'orders and invoices',
    },
  },
  {
    path: '/:id',
    name: 'OrderDetails',
    component: OrderDetails,
    meta: {
      name: 'order detail',
      group: 'orders and invoices',
    },
  },
  {
    path: '/reporting/dac7',
    name: 'Dac7',
    component: Dac7,
    meta: {
      name: 'dac 7 report',
      group: 'orders and invoices',
    },
  },
]

export default routes
