<template>
  <div class="flex flex-col items-center">
    <icon-no-orders class="no-orders-icon" />
    <p class="font-bold text-xl sm:text-2xl" data-cy="noOrdersTitleText">
      {{ titleText }}
    </p>
    <template v-if="appliedFilters && !single">
      <p class="mt-4 mb-1">{{ hintText }}</p>
      <p
        @click="removeFilters"
        class="font-bold text-blue-100 cursor-pointer hover:underline"
        data-cy="removeFilters"
      >
        {{ $t('remove_filters') }}
      </p>
    </template>
  </div>
</template>

<script>
import IconNoOrders from '../icons/IconNoOrders'
import { SET_ORDER_FILTERS } from '../store/mutation.types'
import { GET_ORDER_HISTORY, GET_SUMMARY } from '../store/actions.types'

export default {
  components: {
    IconNoOrders,
  },
  props: {
    single: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    appliedFilters() {
      return (
        this.$store.state.filters.orderFilters.orderStatus.length ||
        this.$store.state.filters.orderFilters.paymentType.length ||
        this.$store.state.filters.orderFilters.serviceType.length
      )
    },
    titleText() {
      return this.single ? this.$t('not_accessible') : this.$t('no_orders')
    },
    hintText() {
      return this.single ? this.$t('not_accessible_hint') : this.$t('change_filters')
    },
  },
  methods: {
    async removeFilters() {
      this.$store.commit(SET_ORDER_FILTERS, {
        orderStatus: [],
        paymentType: [],
        serviceType: [],
      })
      await this.$store.dispatch(GET_ORDER_HISTORY)
      await this.$store.dispatch(GET_SUMMARY)
    },
  },
}
</script>

<style scoped>
@media screen and (max-width: 640px) {
  .no-orders-icon {
    max-width: 9rem;
  }
}
</style>
