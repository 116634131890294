<template>
  <div
    class="card bg-white rounded-sm border border-grey-30 p-4 md:p-6 flex items-center relative flex-wrap"
    :class="order.orderStatus.toLowerCase()"
  >
    <span class="w-1/2 xl:w-1/12 order-1 mb-6 xl:mb-0" data-cy="orderTime">
      {{ $d(new Date(order.placedDate), 'time') }}
    </span>
    <span
      tabindex="0"
      @click="getOrderDetails"
      :class="{
        'opacity-50 cursor-wait hover:no-underline': processingOrderDetails,
      }"
      class="font-bold w-1/2 lg:w-3/12 xl:w-2/12 text-blue-100 hover:underline order-3 xl:order-2 mb-6 lg:mb-0 text-left xl:text-center cursor-pointer"
      data-cy="orderId"
    >
      #{{ order.friendlyId }}
    </span>
    <payment-type
      class="w-1/2 sm:w-1/3 lg:w-3/12 xl:w-2/12 order-5 lg:order-3 mb-0"
      :class="{ 'text-sm sm:text-xs': hasLongTranslation }"
      :payment="order.paymentType"
    />
    <order-type
      class="w-1/2 sm:w-1/3 order-6 lg:order-4 lg:w-2/12 xl:w-2/12"
      :class="{ 'text-sm sm:text-xs': hasLongTranslation }"
      :type="order.serviceType"
    />
    <span class="flex w-1/2 xl:w-1/12 justify-end order-2 xl:order-5 mb-6 xl:mb-0 relative">
      <order-status :status="order.orderStatus" />
      <hr class="line block xl:hidden border-grey-20 absolute left-0 transform -translate-x-1/2" />
    </span>
    <span
      class="w-full sm:w-1/3 mt-4 sm:mt-0 whitespace-no-wrap lg:w-3/12 xl:w-3/12 font-bold sm:text-right lg:text-left xl:text-right order-7 lg:order-6"
      data-cy="deliveryPrice"
    >
      {{ $n(deliveryPrice, 'currency') }}
      <span class="sm:text-xs">{{ $t('delivery_charges') }}</span>
    </span>
    <span
      class="w-1/2 lg:w-1/12 xl:w-1/12 font-bold text-right order-4 lg:order-7 mb-6 lg:mb-0"
      data-cy="orderPrice"
    >
      {{ $n(price, 'currency') }}
    </span>
  </div>
</template>

<script>
import PaymentType from './PaymentType'
import OrderType from './OrderType'
import OrderStatus from './OrderStatus'
import { GER_ORDER_DETAILS } from '../store/actions.types'
import { TENANT } from '../constants'

export default {
  components: {
    PaymentType,
    OrderType,
    OrderStatus,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processingOrderDetails: false,
    }
  },
  computed: {
    price() {
      return this.order.orderPrice / 100
    },
    deliveryPrice() {
      return this.order.deliveryPrice / 100
    },
    hasLongTranslation() {
      return TENANT === 'es' || TENANT === 'it'
    },
  },
  methods: {
    async getOrderDetails() {
      if (this.processingOrderDetails) return
      this.processingOrderDetails = true
      await this.$store.dispatch(GER_ORDER_DETAILS, this.order.id)
      await this.$router.push({
        name: 'OrderDetails',
        params: { id: this.order.friendlyId },
      })
      this.processingOrderDetails = false
    },
  },
}
</script>

<style scoped>
.card:before {
  content: '';
  position: absolute;
  background-color: transparent;
  width: 4px;
  left: 0;
  top: 0;
  height: 100%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.card.declined:before {
  background-color: #e2e6e9;
}

.card.completed:before {
  background-color: #017a39;
}

.card.cancelled:before {
  background-color: #d50525;
}

.line {
  bottom: -12px;
  width: 200%;
}
</style>
