<template>
  <span class="inline-flex items-center">
    <icon-card class="flex-shrink-0" v-if="isCardPayment" />
    <icon-cash class="flex-shrink-0" v-else />
    <span class="ml-2" data-cy="orderPaymentType">
      {{ $t(payment.toLowerCase()) }}
    </span>
  </span>
</template>

<script>
import IconCard from '../icons/IconCard'
import IconCash from '../icons/IconCash'
export default {
  components: {
    IconCard,
    IconCash,
  },
  props: {
    payment: {
      type: String,
      required: true,
    },
  },
  computed: {
    isCardPayment() {
      return this.payment === 'Card'
    },
  },
}
</script>
