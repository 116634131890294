import { ApiClient } from '@je-pc/api'
import { tokenTypesConfig } from '@je-pc/auth'
import { SMARTGATEWAY_URL } from '../constants'

const httpKeycloakClient = new ApiClient(SMARTGATEWAY_URL, true, true, {
  tokenType: tokenTypesConfig.list.keycloak,
})

export const getOrderHistory = (params) => {
  return httpKeycloakClient.get(
    '/applications/partnerhuborderhistory/{tenant}/{restaurantId}/orders',
    {
      params,
    }
  )
}

export const getSummary = (params) => {
  return httpKeycloakClient.get(
    '/applications/partnerhuborderhistory/{tenant}/{restaurantId}/ordersummaries',
    {
      params,
    }
  )
}

export const getOrderDetails = (id, params) => {
  return httpKeycloakClient.get(
    `/applications/partnerhuborderhistory/{tenant}/{restaurantId}/orders/${id}`,
    {
      params,
    }
  )
}

export const getDac7SellerReport = async (params) => {
  return httpKeycloakClient.get(`/applications/partnerhelp/{tenant}/{restaurantId}/dac7`, {
    params,
  })
}
