import Vue from 'vue'
import VueRouter from 'vue-router'
import { beforeRouteEnterHook } from '@je-pc/auth'
import { trackPageTransition } from '@je-pc/tracking'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  beforeRouteEnterHook(to, from, next)
})

trackPageTransition(router)

export default router
