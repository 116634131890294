<template>
  <span class="inline-flex items-center">
    <component :is="type" class="flex-shrink-0" />
    <span class="ml-2" data-cy="orderServiceType">
      {{ $t(type.toLowerCase()) }}
    </span>
  </span>
</template>

<script>
import Delivery from '../icons/IconDelivery'
import Collection from '../icons/IconCollection'
import DineIn from '../icons/IconDineIn'
export default {
  components: {
    Delivery,
    Collection,
    DineIn,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
</script>
