<template>
  <label
    class="pill border border-solid border-grey-40 flex px-4 py-1 rounded-2xl text-sm cursor-pointer"
    :class="shouldBeChecked && 'border-black bg-dark text-white'"
    tabindex="0"
    @keyup.enter="$refs.check.click()"
    @keyup.space="$refs.check.click()"
  >
    <input
      :value="data"
      :checked="shouldBeChecked"
      class="hidden"
      @change="updatePill"
      type="checkbox"
      ref="check"
    />
    <div class="font-bold">
      <slot />
    </div>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    data: {
      type: [String, Array],
      required: true,
    },
    model: {
      default: false,
    },
  },
  computed: {
    shouldBeChecked() {
      if (this.model instanceof Array) {
        return this.model.includes(this.data)
      }
      return this.model
    },
  },
  methods: {
    updatePill(event) {
      let isChecked = event.target.checked
      if (this.model instanceof Array) {
        let newValue = [...this.model]

        if (isChecked) {
          newValue.push(this.data)
        } else {
          newValue.splice(newValue.indexOf(this.data), 1)
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked)
      }
    },
  },
}
</script>

<style scoped>
.pill:not(.bg-dark):hover {
  background-color: #f5f5f5;
}
</style>
