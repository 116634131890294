<template>
  <div class="flex flex-col relative">
    <div class="space-y-6">
      <div
        class="flex flex-col pb-6 border-b border-grey-30 last:border-b-0"
        v-for="day in sortedOrders"
        :key="day.date"
      >
        <span class="order-date mb-4 text-xl font-bold inline-flex items-end flex-none w-full">
          <span class="text-sm lg:text-base font-normal uppercase leading-none">
            {{ weekDay(day.date) }}
          </span>
          <span class="leading-none ml-2" data-cy="orderDate">
            {{ longDay(day.date) }}
          </span>
        </span>
        <div class="flex flex-col flex-1 space-y-4">
          <order-card :order="order" v-for="order in day.orders" :key="order.id" />
        </div>
      </div>
    </div>
    <div ref="loadingObserver" v-show="!isLoadingInfinite"></div>
    <pc-back-to-top-button button-class="md:ml-6" :text="$t('back_to_top')" />
  </div>
</template>

<script>
import { PcBackToTopButton } from '@je-pc/ui-components'
import OrderCard from './OrderCard'
import { GET_ORDER_HISTORY_NEXT } from '../store/actions.types'

export default {
  components: {
    OrderCard,
    PcBackToTopButton,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      observer: null,
    }
  },
  computed: {
    sortedOrders() {
      const orders = this.orders.reduce((groups, order) => {
        const date = order.placedDate.split('T')[0]
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(order)
        return groups
      }, {})
      return Object.keys(orders).map((date) => {
        return {
          date,
          orders: orders[date],
        }
      })
    },
    isLoadingInfinite() {
      return this.$store.state.isLoadingInfinite
    },
  },
  mounted() {
    const element = this.$refs.loadingObserver
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.handleInfinityScroll()
      }
    })
    this.observer.observe(element)
  },
  destroyed() {
    this.observer.disconnect()
  },
  methods: {
    async handleInfinityScroll() {
      await this.$store.dispatch(GET_ORDER_HISTORY_NEXT)
    },
    weekDay(date) {
      return this.$d(new Date(date), 'weekday')
    },
    longDay(date) {
      return this.$d(new Date(date), 'day')
    },
  },
}
</script>
