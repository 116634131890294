<template>
  <div class="relative">
    <button
      tabindex="0"
      class="w-full border border-solid border-black bg-dark text-white flex px-4 md:px-6 py-2 rounded-2xl cursor-pointer items-center space-x-2 max-h-32 justify-center md:justify-start"
      @click="isOpen = !isOpen"
    >
      <span class="leading-none font-bold text-sm inline-flex items-center" data-cy="datePicker">
        <icon-calendar class="text-white mr-2 hidden sm:block" />
        <span>
          {{ datePickerMessage }}
        </span>
      </span>
    </button>
    <fade-transition>
      <div @click="isOpen = false" v-if="isOpen && isMobile" class="fixed inset-0 z-10">
        <div class="absolute inset-0 bg-grey-100 opacity-50" />
      </div>
    </fade-transition>
    <component :is="transition">
      <div
        v-if="isOpen"
        class="dropdown mt-2 z-10 p-4 bg-white shadow-md rounded-sm"
        :class="[
          isMobile
            ? 'fixed w-full bottom-0 left-0'
            : 'absolute transform translate-x-1/2 right-half',
        ]"
        @keyup.esc="isOpen = false"
      >
        <p class="relative mb-6">
          <span class="font-bold">{{ $t('date_range') }}</span>
          <button @click="isOpen = false" class="absolute top-0 right-0">
            <svg
              class="w-6 h-6 text-blue-100"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </p>
        <div class="flex space-x-2 mb-6" v-if="isMobile">
          <button
            class="border border-solid flex px-2 py-1 w-1/3 rounded-2xl text-sm cursor-pointer justify-center"
            @click="selectDay"
            :class="range === 'day' ? 'border-black bg-dark text-white' : 'border-grey-40'"
          >
            {{ $t('yesterday') }}
          </button>
          <button
            class="border border-solid flex px-2 py-1 w-1/3 rounded-2xl text-sm cursor-pointer justify-center"
            @click="selectWeek"
            :class="range === 'week' ? 'border-blue-100 bg-dark text-white' : 'border-grey-40'"
          >
            {{ $t('last_week') }}
          </button>
          <button
            class="border border-solid flex px-2 py-1 w-1/3 rounded-2xl text-sm cursor-pointer justify-center"
            @click="selectMonth"
            :class="range === 'month' ? 'border-blue-100 bg-dark text-white' : 'border-grey-40'"
          >
            {{ $t('last_month') }}
          </button>
        </div>
        <date-picker
          v-model="dateRange"
          :locale="$i18n.locale"
          ref="calendar"
          :select-attribute="selectAttribute"
          :drag-attribute="dragAttribute"
          :attributes="attrs"
          :navVisibility="'hidden'"
          is-range
          :columns="isMobile ? 1 : 2"
          :first-day-of-week="2"
          :masks="{ weekdays: 'WW' }"
          @input="handleDateChange"
          :min-date="minDate"
          :max-date="new Date()"
        >
          <template v-slot:footer>
            <div class="flex items-center border-t border-b border-grey-20 py-2 mt-4">
              <icon-idea />
              <div class="ml-1" v-html="$t('one_day_tip')"></div>
            </div>
            <div class="flex items-center justify-end md:justify-between pt-4">
              <div class="flex space-x-2 items-center" v-if="!isMobile">
                <button
                  class="pill-button border border-solid border-grey-40 flex px-4 py-2 rounded-2xl cursor-pointer items-center max-h-32 justify-center leading-4 text-sm font-bold"
                  data-cy="yesterdayDatePicker"
                  @click="selectDay"
                  :class="
                    range === 'day' ? 'border-blue-100 bg-blue-100 text-white' : 'border-grey-40'
                  "
                >
                  <span>{{ $t('yesterday') }}</span>
                </button>
                <button
                  class="pill-button border border-solid border-grey-40 flex px-4 py-2 rounded-2xl cursor-pointer items-center max-h-32 justify-center leading-4 text-sm font-bold"
                  data-cy="lastWeekDatePicker"
                  @click="selectWeek"
                  :class="
                    range === 'week' ? 'border-blue-100 bg-blue-100 text-white' : 'border-grey-40'
                  "
                >
                  {{ $t('last_week') }}
                </button>
                <button
                  class="pill-button border border-solid border-grey-40 flex px-4 py-2 rounded-2xl cursor-pointer items-center max-h-32 justify-center leading-4 text-sm font-bold"
                  data-cy="lastMonthDatePicker"
                  @click="selectMonth"
                  :class="
                    range === 'month' ? 'border-blue-100 bg-blue-100 text-white' : 'border-grey-40'
                  "
                >
                  {{ $t('last_month') }}
                </button>
              </div>
              <div class="flex space-x-2">
                <pc-button @click="clearFilters" type="ghost" data-cy="clearDatePicker">
                  {{ $t('clear') }}
                </pc-button>
                <pc-button @blur="isOpen = false" @click="applyFilter" data-cy="applyDatePicker">
                  {{ $t('apply') }}
                </pc-button>
              </div>
            </div>
          </template>
        </date-picker>
      </div>
    </component>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {
  PcButton,
  isMobile,
  clickOutside,
  FadeTransition,
  SlideYDownTransition,
} from '@je-pc/ui-components'
import IconCalendar from '../icons/IconCalendar'
import IconIdea from '../icons/IconIdea'
import { mapGetters } from 'vuex'
import { subDays, format } from 'date-fns'
import { trackDateRangeChanges } from '../services/gtm'
import { SET_DATE_FILTER, SET_SELECTED_RANGE } from '../store/mutation.types'
import { GET_ORDER_HISTORY, GET_SUMMARY } from '../store/actions.types'

export default {
  components: {
    PcButton,
    IconCalendar,
    IconIdea,
    FadeTransition,
    SlideYDownTransition,
    DatePicker,
  },
  mixins: [isMobile, clickOutside],
  props: {
    label: {
      type: String,
      default: 'Date range',
    },
  },
  data() {
    return {
      range: '',
      dateRange: {},
      today: new Date(),
      minDate: subDays(new Date(), 30),
      selectAttribute: {
        highlight: {
          base: {
            class: 'day-range-bg',
            contentClass: 'day-range',
          },
          start: {
            class: 'day-range-bg-right-left',
            contentClass: 'day-range-right-left',
          },
          end: {
            class: 'day-range-bg-right-left',
            contentClass: 'day-range-right-left',
          },
        },
      },
      dragAttribute: {
        highlight: {
          base: {
            class: 'day-range-bg',
            contentClass: 'day-range',
          },
          start: {
            class: 'day-range-bg-right-left',
            contentClass: 'day-range-right-left',
          },
          end: {
            class: 'day-range-bg-right-left',
            contentClass: 'day-range-right-left',
          },
        },
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            contentStyle: {
              color: '#125fca',
              'background-color': '#fff',
              'font-weight': 'bold',
            },
            class: 'today',
          },
          dates: new Date(),
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['dateFilter']),
    transition() {
      return this.isMobile ? 'SlideYDownTransition' : 'FadeTransition'
    },
    selectedRange() {
      return this.$store.state.filters.selectedRange
    },
    datePickerMessage() {
      const { start, end } = this.dateFilter
      if (start.getTime() === end.getTime()) {
        return format(start, 'dd/MM/yy')
      }
      return `${format(start, 'dd/MM/yy')} - ${format(end, 'dd/MM/yy')}`
    },
  },
  watch: {
    isOpen() {
      this.dateRange = this.dateFilter
      this.range = this.selectedRange
    },
  },
  methods: {
    format,
    selectMonth() {
      this.range = 'month'
      const date = {
        start: subDays(new Date(), 29),
        end: new Date(),
      }
      this.dateRange = date
      this.focusCalendar(subDays(new Date(), 29))
    },
    selectWeek() {
      this.range = 'week'
      const date = {
        start: subDays(new Date(), 6),
        end: new Date(),
      }
      this.dateRange = date
      this.focusCalendar(subDays(new Date(), 6))
    },
    selectDay() {
      this.range = 'day'
      const date = {
        start: subDays(new Date(), 1),
        end: subDays(new Date(), 1),
      }
      this.dateRange = date
      this.focusCalendar(subDays(new Date(), 1))
    },
    focusCalendar(date) {
      const calendar = this.$refs.calendar
      calendar.focusDate(date)
    },
    async applyFilter() {
      this.$store.commit(SET_DATE_FILTER, this.dateRange)
      this.$store.commit(SET_SELECTED_RANGE, this.range)
      this.isOpen = false
      await this.$store.dispatch(GET_ORDER_HISTORY)
      await this.$store.dispatch(GET_SUMMARY)
      trackDateRangeChanges(this.dateRange)
    },
    clearFilters() {
      this.selectMonth()
      this.applyFilter()
    },
    handleDateChange() {
      this.$store.commit(SET_SELECTED_RANGE, '')
      this.range = ''
    },
  },
}
</script>

<style scoped>
.dropdown >>> .vc-container {
  border: none;
  font-family: JustEatBasis, sans-serif !important;
  width: 700px;
}
.dropdown >>> .vc-header {
  padding-top: 0;
  font-size: 20px;
}
.dropdown >>> .vc-pane-layout .vc-pane:first-of-type .vc-weeks {
  padding-left: 0;
}
.dropdown >>> .vc-pane-layout .vc-pane:last-of-type .vc-weeks {
  padding-right: 0;
}
.dropdown >>> .vc-arrows-container {
  padding: 0;
}
.dropdown >>> .vc-weekday {
  font-size: 16px;
  font-weight: normal;
  color: #929faa;
}
.dropdown >>> .day-range-bg {
  background-color: #e7f1fe;
}
.dropdown >>> .day-range {
  background-color: inherit;
  font-weight: normal;
}
.dropdown >>> .day-range-bg-right-left {
  background-color: #125fca;
}

.dropdown >>> .day-range-right-left {
  color: white;
  font-weight: bold;
}

.dropdown >>> .day-range-right-left:hover,
.dropdown >>> .day-range-right-left:focus {
  background-color: inherit;
}
.dropdown >>> .vc-day-content.is-disabled:hover,
.dropdown >>> .vc-day-content.is-disabled:focus {
  background-color: inherit;
  font-weight: normal;
}
@media screen and (max-width: 640px) {
  .dropdown >>> .vc-header {
    margin-bottom: 24px;
  }
  .dropdown >>> .vc-container {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dropdown {
    right: 0;
    transform: translateX(70%);
  }
}

.pill-button:not(.border-blue-100):focus {
  box-shadow: 0 0 0 2px #125fca;
}
.pill-button:not(.border-blue-100):hover {
  background-color: #f5f5f5;
}

.pill-button:focus {
  box-shadow: 0 0 0 2px #f36d00;
}
</style>
