<template>
  <span class="inline-flex text-xs px-2 box-border" :class="getClass" data-cy="orderStatus">
    {{ $t(statusValue) }}
  </span>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    getClass() {
      const classes = {
        cancelled: 'bg-red-10 text-red-100',
        completed: 'bg-green-10',
        declined: 'bg-grey-30',
      }
      return classes[this.statusValue]
    },
    statusValue() {
      return this.status.toLowerCase()
    },
  },
}
</script>
