<template>
  <div class="animate-pulse flex flex-col md:flex-row space-x-0 md:space-x-6 items-start">
    <div class="flex flex-col relative order-3 md:order-1 w-full md:w-8/12 lg:w-9/12">
      <div class="space-y-6">
        <div
          class="flex flex-col pb-6 border-b border-grey-30 last:border-b-0"
          v-for="(n, i) in 2"
          :key="n + i"
        >
          <span class="order-date mb-4 text-xl font-bold inline-flex items-end flex-none w-full">
            <span
              class="text-sm lg:text-base font-normal uppercase leading-none bg-grey-30 h-5 w-6"
            ></span>
            <span class="leading-none ml-2 h-5 w-12 bg-grey-30"></span>
          </span>
          <div class="flex flex-col flex-1 space-y-4 w-full">
            <div
              v-for="m in 3"
              :key="m"
              class="bg-grey-30 rounded-sm border border-grey-30 p-4 md:p-6 flex items-center relative flex-wrap"
            >
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="order-2 border-grey-30 my-6 md:hidden w-full" />
    <div
      class="order-1 md:order-3 w-full md:w-4/12 lg:w-3/12 bg-grey-30 border border-solid border-grey-30"
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListLoader',
}
</script>
