<template>
  <div class="you-loan-banner bg-container-default shadow-elevation-01 rounded-radius-c">
    <div class="banner-content">
      <h3 class="pc-heading-m mb-1 banner-title">{{ $t('banner_title') }}</h3>
      <p class="banner-description">
        {{ $t('banner_text') }}
      </p>
    </div>

    <div class="you-loan-banner-image">
      <div class="you-loan-banner-image-wrapper">
        <icon-announcement class="icon-svg" />
      </div>
    </div>

    <div class="button-section">
      <pc-button id="loanBannerLink" size="small" :href="$t('banner_url')">
        {{ $t('banner_cta') }}
      </pc-button>
    </div>
  </div>
</template>

<script>
import IconAnnouncement from '../icons/IconAnnouncement'
import { PcButton } from '@je-pc/ui-components'

export default {
  components: {
    IconAnnouncement,
    PcButton,
  },
}
</script>

<style scoped>
/* TODO: implement PIE Design replacing Icing  */

.you-loan-banner {
  padding: 24px;
  gap: 16px;
  justify-content: space-between;
  min-height: 112px;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.you-loan-banner .banner-content {
  order: 1;
}

.you-loan-banner .button-section {
  min-width: fit-content;
  order: 3;
}

.you-loan-banner .button-section #loanBannerLink {
  font-size: 16px; /* Make it fit design requirements */
  background-color: #1a1a19; /* Make it fit rest of the page */
}

.you-loan-banner .you-loan-banner-image {
  order: 2;
}
.you-loan-banner .you-loan-banner-image .you-loan-banner-image-wrapper {
  margin-top: -32px;
  margin-bottom: -44px;
}

@media (max-width: 768px) {
  .you-loan-banner {
    flex-direction: column;
    padding: 16px;
  }
  .you-loan-banner .banner-content {
    max-width: 100%;
    margin-right: 60px; /* prevent image overlapping the text on mobile device  */
  }

  .you-loan-banner .you-loan-banner-image {
    margin-bottom: -16px;
    margin-top: -124px;
    order: 3;
    margin-right: -16px;
    align-self: end;
  }

  .you-loan-banner .you-loan-banner-image .you-loan-banner-image-wrapper {
    margin: 0;
  }

  .you-loan-banner svg {
    width: 122px;
    height: 122px;
  }

  .you-loan-banner .button-section {
    order: 2;
    align-self: start;
  }
}
</style>
