export const SET_DATE_FILTER = 'setDateFilter'
export const SET_ORDERS = 'setOrders'
export const SET_SUMMARY = 'setSummary'
export const SET_ORDER_DETAILS = 'setOrderDetails'
export const SET_CURSOR = 'setCursor'
export const SET_NEW_ORDERS = 'setNewOrders'
export const TOGGLE_IS_LOADING = 'toggleIsLoading'
export const SET_ORDER_FILTERS = 'setOrderStatusFilter'
export const SET_SELECTED_RANGE = 'setSelectedRange'
export const SET_IS_LOADING_DETAILS = 'setIsLoadingDetails'
export const SET_IS_LOADING_INFINITE = 'setIsLoadingInfinite'
export const SET_USER = 'setUser'
export const SET_DAC7_SELLER_REPORT = 'setDac7SellerReport'
