<template>
  <div>
    <pc-heading-provider>
      <template #leftSlot>
        <pc-heading-link variant="back" :to="backHeadingLink" />
      </template>
    </pc-heading-provider>
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
import Spinner from '../components/Spinner.vue'
import { PcHeadingProvider, PcHeadingLink } from '@je-pc/navigation'
import { GET_DAC7_SELLER_REPORT } from '../store/actions.types'
export default {
  title() {
    return 'DAC7'
  },

  components: {
    Spinner,
    PcHeadingProvider,
    PcHeadingLink,
  },
  computed: {
    backHeadingLink() {
      return '/orders-and-invoices'
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    dac7SellerReport() {
      return this.$store.getters.dac7SellerReport
    },
  },
  async mounted() {
    await this.$store.dispatch(GET_DAC7_SELLER_REPORT)
    const report = this.dac7SellerReport
    window.location.href = report.url
  },
}
</script>
