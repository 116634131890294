<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <div class="bg-white border border-solid border-grey-30">
    <div class="bg-orange-10 p-4">
      <p class="mb-1">
        {{ $t('total') }}
      </p>
      <p class="font-bold mb-2 md:text-3xl leading-none" data-cy="totalOrderPrice">
        <i18n-n :value="totalValue / 100" format="currency">
          <template v-slot:currency="slotProps">
            <span>{{ slotProps.currency }}</span>
          </template>
          <template v-slot:integer="slotProps" styles="font-weight: bold">
            <span class="text-3xl">{{ slotProps.integer }}</span>
          </template>
          <template v-slot:decimal="slotProps" styles="font-weight: bold">
            <span>{{ slotProps.decimal }}</span>
          </template>
          <template v-slot:fraction="slotProps" styles="font-size: small">
            <span>{{ slotProps.fraction }}</span>
          </template>
        </i18n-n>
      </p>
      <p class="text-grey-50 text-sm mb-2">{{ $t('include_fees') }}</p>
      <div class="flex">
        <pc-chip
          v-for="(item, i) in feesList"
          :key="i"
          v-model="selectedFees"
          :value="item"
          :data-cy="`${item}FeeChip`"
          type="outline"
          class="mr-2 items-center"
          @change="onFeeToggle(item)"
        >
          <span class="w-4 h-4 mr-2 flex items-center justify-center">
            <pc-icon
              :class="{ 'w-3 h-3': getIconComponentName(item) === 'plus' }"
              :name="getIconComponentName(item)"
            />
          </span>
          <span>{{ $t(item) }}</span>
        </pc-chip>
      </div>
      <hr class="my-4 border-grey-30" />
      <div class="text-grey-50 text-sm">
        <p>{{ info.count }} {{ $tc('orders', info.count) }}</p>
        <p>
          <span data-cy="dateFrom">
            {{ $d(new Date(dateFilter.start), 'short') }}
          </span>
          -
          <span data-cy="dateTo">
            {{ $d(new Date(dateFilter.end), 'short') }}
          </span>
        </p>
      </div>
    </div>
    <div class="px-3 py-4">
      <button
        @click="toggleInfo"
        class="w-full flex font-bold text-blue-100 items-center justify-between cursor-pointer leading-normal hover:underline focus:outline-none focus:shadow-outline"
        data-cy="showAdditionalDetails"
      >
        {{ isOpened ? $t('hide_details') : $t('show_details') }}
        <svg
          class="transition duration-300 ease-in-out w-6 h-6"
          :class="{ 'transform rotate-180': isOpened }"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      <collapse-transition>
        <div class="flex flex-col space-y-4 mt-6" v-if="isOpened">
          <span class="inline-flex justify-between">
            <span>
              {{ $t('avg_value') }}
              <br />
              <span class="text-grey-50 text-sm">
                {{ $t('food_and_drinks') }}
              </span>
            </span>
            <span class="font-bold" data-cy="totalOrderValue">
              {{ $n(totalValueWithoutFees / info.count / 100, 'currency') }}
            </span>
          </span>
          <span class="inline-flex items-center justify-between">
            <span>{{ $t('delivery_orders') }}</span>
            <span class="font-bold" data-cy="totalDeliveryOrderValue">
              {{ $n(info.totalDeliveryOrderValue / 100, 'currency') }}
            </span>
          </span>
          <span class="inline-flex items-center justify-between">
            <span>{{ $t('collection_orders') }}</span>
            <span class="font-bold" data-cy="totalCollectionOrderValue">
              {{ $n(info.totalCollectionOrderValue / 100, 'currency') }}
            </span>
          </span>
          <span class="inline-flex items-center justify-between" v-if="dineInEnabled">
            <span>{{ $t('dinein_orders') }}</span>
            <span class="font-bold" data-cy="totalDineInOrderValue">
              {{ $n(info.totalDineInOrderValue / 100, 'currency') }}
            </span>
          </span>
          <span class="inline-flex items-center justify-between">
            <span>{{ $t('cash_payments') }}</span>
            <span class="font-bold" data-cy="totalCashOrderValue">
              {{ $n(info.totalCashOrderValue / 100, 'currency') }}
            </span>
          </span>
          <span class="inline-flex items-center justify-between">
            <span>{{ $t('card_payments') }}</span>
            <span class="font-bold" data-cy="totalCardOrderValue">
              {{ $n(info.totalCardOrderValue / 100, 'currency') }}
            </span>
          </span>
          <hr v-if="selectedFees.length !== 0" class="border-grey-30 mt-6 mb-4" />
          <span v-if="isFeeIncluded('service')" class="inline-flex justify-between">
            <span>
              {{ $t('service_charge') }}
              <span v-if="$te('service_charge_note')" class="text-grey-50 text-sm">
                <br />
                {{ $t('service_charge_note') }}
              </span>
            </span>
            <span class="font-bold" data-cy="totalServiceChargesValue">
              {{ $n(info.totalServiceFeeValue / 100, 'currency') }}
            </span>
          </span>
          <span v-if="isFeeIncluded('delivery')" class="inline-flex items-center justify-between">
            <span>{{ $t('delivery_fee') }}</span>
            <span class="font-bold" data-cy="totalDeliveryFeesValue">
              {{ $n(info.totalDeliveryPriceValue / 100, 'currency') }}
            </span>
          </span>
          <hr class="border-grey-30 mt-6 mb-4" />
          <div class="flex">
            <icon-tip />
            <div class="ml-3">
              <p>{{ $t('tip_title') }}</p>
              <pc-global-link
                class="text-blue-100 font-bold"
                data-cy="liveReportingLink"
                to="/live-reporting"
              >
                {{ $t('tip_text') }}
              </pc-global-link>
            </div>
          </div>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition, PcChip, PcIcon, PcGlobalLink } from '@je-pc/ui-components'
import { mapGetters } from 'vuex'
import { trackRestaurantDetailsToggle, trackFeesToggle } from '../services/gtm'
import IconTip from '../icons/IconTip'
import { dineInEnabled } from '../constants'

export default {
  components: {
    CollapseTransition,
    IconTip,
    PcChip,
    PcIcon,
    PcGlobalLink,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dineInEnabled,
      isOpened: false,
      feesList: ['service', 'delivery'],
      selectedFees: [],
    }
  },
  computed: {
    ...mapGetters(['dateFilter']),
    user() {
      return this.$store.state.user
    },
    includedDeliveryFeesValue() {
      return this.isFeeIncluded('delivery') ? 0 : this.info.totalDeliveryPriceValue
    },
    includedServiceChargesValue() {
      return this.isFeeIncluded('service') ? 0 : this.info.totalServiceFeeValue
    },
    totalValueWithoutFees() {
      return (
        this.info.totalOrderValue -
        (this.info.totalServiceFeeValue + this.info.totalDeliveryPriceValue)
      )
    },
    totalValue() {
      return (
        this.info.totalOrderValue -
        (this.includedDeliveryFeesValue + this.includedServiceChargesValue)
      )
    },
  },
  methods: {
    toggleInfo() {
      this.isOpened = !this.isOpened
      const action = this.isOpened ? 'show' : 'hide'
      trackRestaurantDetailsToggle(this.user.restaurant.id, action)
    },
    isFeeIncluded(feeType) {
      return this.selectedFees.includes(feeType)
    },
    getIconComponentName(feeType) {
      return this.isFeeIncluded(feeType) ? 'check' : 'plus'
    },
    onFeeToggle(feeType) {
      const isFeeIncluded = this.isFeeIncluded(feeType)
      const action = isFeeIncluded ? 'add' : 'remove'
      trackFeesToggle(feeType, isFeeIncluded, action)
    },
  },
}
</script>
