import { trackCustomEvent } from '@je-pc/tracking'
import { format } from 'date-fns'
import i18n from '../i18n'

const trackFilterInteraction = (
  component_name,
  component_type,
  filter_name,
  filter_value,
  filter_action
) => {
  const eventName = `filter_${filter_action}`
  const trackingObject = {
    component_name,
    component_type,
    filter_name,
    filter_value,
    filter_action,
  }
  trackCustomEvent(eventName, trackingObject)
}

export const trackRestaurantData = (data) => {
  const getFormatedValue = (value) => {
    return i18n.n(value / 100, 'currency')
  }
  const trackingObject = {
    restaurant: {
      transactionData: {
        revenue: {
          total: getFormatedValue(data.totalOrderValue),
          totalDelivery: getFormatedValue(data.totalDeliveryPriceValue),
        },
        orders: {
          total: data.count,
        },
      },
    },
  }
  trackCustomEvent('', trackingObject)
}

export const trackDateRangeChanges = (dateRange) => {
  const from = format(dateRange.start, 'yyyy:MM:dd')
  const to = format(dateRange.end, 'yyyy:MM:dd')
  const filterValue = `${from} - ${to}`
  trackFilterInteraction('dateGride', 'grid', 'date', filterValue, 'add')
}

export const trackFiltersChanges = (filters) => {
  const filtersList = Object.keys(filters).map((item) => {
    return {
      filterName: item,
      filterValue: filters[item],
    }
  })
  filtersList.forEach((item) => {
    if (item.filterValue.length === 0) {
      return
    }
    trackFilterInteraction(
      'restaurantOrderHistoryForm',
      'form',
      item.filterName,
      item.filterValue.join(', '),
      'add'
    )
  })
}

export const trackFeesToggle = (name, value, action) => {
  trackFilterInteraction('restaurantOrderHistorySummary', 'list', `${name}Fee`, value, action)
}

export const trackOrderDetailsView = (transactionId) => {
  trackCustomEvent('', {
    transactionData: {
      'order.key': transactionId,
    },
  })
  trackCustomEvent('trackEvent', {
    eventCategory: 'engagement',
    eventAction: 'order_view',
    eventLabel: transactionId,
  })
}

export const trackRestaurantDetailsToggle = (restaurantId, action) => {
  const eventName = `orderHistorySummary_${action}`
  const trackingObject = {
    component: {
      name: 'orderHistorySummary',
      type: 'restrauntDetail',
      id: restaurantId,
    },
    order: {
      action,
      key: restaurantId,
    },
  }
  trackCustomEvent(eventName, trackingObject)
}
