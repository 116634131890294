import Vue from 'vue'
import Vuex from 'vuex'
import { format, subDays } from 'date-fns'
import { orderStatusFilters, paymentTypeFilters, serviceTypeFilters } from './helpers'
import {
  SET_DATE_FILTER,
  SET_ORDERS,
  SET_SUMMARY,
  SET_ORDER_DETAILS,
  SET_CURSOR,
  SET_NEW_ORDERS,
  TOGGLE_IS_LOADING,
  SET_ORDER_FILTERS,
  SET_SELECTED_RANGE,
  SET_IS_LOADING_DETAILS,
  SET_IS_LOADING_INFINITE,
  SET_USER,
  SET_DAC7_SELLER_REPORT,
} from './mutation.types'
import {
  GET_ORDER_HISTORY,
  GET_SUMMARY,
  GER_ORDER_DETAILS,
  GET_ORDER_HISTORY_NEXT,
  GET_DAC7_SELLER_REPORT,
} from './actions.types'
import { getOrderHistory, getSummary, getOrderDetails, getDac7SellerReport } from '../services/http'
import { trackRestaurantData } from '../services/gtm'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    filters: {
      date: {
        start: subDays(new Date(), 29),
        end: new Date(),
      },
      selectedRange: 'month',
      orderFilters: {
        orderStatus: ['Completed'],
        paymentType: [],
        serviceType: [],
      },
    },
    summary: null,
    orders: [],
    orderDetails: null,
    cursor: '',
    isLoading: false,
    isLoadingDetails: false,
    isLoadingInfinite: false,
    user: null,
    dac7SellerReport: null,
  },
  getters: {
    dateFilter(state) {
      return state.filters.date
    },
    orderStatus(state) {
      return state.filters.orderFilters.orderStatus.length === orderStatusFilters.length
        ? 'All'
        : state.filters.orderFilters.orderStatus
    },
    paymentType(state) {
      return state.filters.orderFilters.paymentType.length === paymentTypeFilters.length
        ? 'All'
        : state.filters.orderFilters.paymentType
    },
    serviceType(state) {
      return state.filters.orderFilters.serviceType.length === serviceTypeFilters.length
        ? 'All'
        : state.filters.orderFilters.serviceType
    },
    requestParams(state, getters) {
      const params = {
        from: format(state.filters.date.start, 'yyyy-MM-dd'),
        to: format(state.filters.date.end, 'yyyy-MM-dd'),
      }
      if (getters.orderStatus) {
        params.orderStatus = getters.orderStatus
      }
      if (getters.paymentType) {
        params.paymentType = getters.paymentType
      }
      if (getters.serviceType) {
        params.serviceType = getters.serviceType
      }
      return params
    },
    dac7SellerReport(state) {
      return state.dac7SellerReport
    },
  },
  mutations: {
    [SET_DATE_FILTER](state, date) {
      state.filters.date = date
    },
    [SET_ORDERS](state, orders) {
      state.orders = orders
    },
    [SET_SUMMARY](state, summary) {
      state.summary = summary
    },
    [SET_ORDER_DETAILS](state, orderDetails) {
      state.orderDetails = orderDetails
    },
    [SET_DAC7_SELLER_REPORT](state, dac7SellerReport) {
      state.dac7SellerReport = dac7SellerReport
    },
    [SET_CURSOR](state, cursor) {
      state.cursor = cursor
    },
    [SET_NEW_ORDERS](state, orders) {
      state.orders = [...state.orders, ...orders]
    },
    [TOGGLE_IS_LOADING](state, isLoading) {
      state.isLoading = isLoading
    },
    [SET_ORDER_FILTERS](state, filters) {
      state.filters.orderFilters = filters
    },
    [SET_SELECTED_RANGE](state, range) {
      state.filters.selectedRange = range
    },
    [SET_IS_LOADING_DETAILS](state, value) {
      state.isLoadingDetails = value
    },
    [SET_IS_LOADING_INFINITE](state, value) {
      state.isLoadingInfinite = value
    },
    [SET_USER](state, value) {
      state.user = value
    },
  },
  actions: {
    async [GET_ORDER_HISTORY]({ commit, getters }) {
      const params = getters.requestParams
      commit(TOGGLE_IS_LOADING, true)
      const response = await getOrderHistory(params)
      commit(TOGGLE_IS_LOADING, false)
      commit(SET_ORDERS, response.data.orders)
      commit(SET_CURSOR, response.data.paging.cursors.after)
    },
    async [GET_ORDER_HISTORY_NEXT]({ state, commit, getters }) {
      if (!state.cursor || state.isLoadingInfinite) return
      const params = { ...getters.requestParams }
      params.after = state.cursor
      commit(SET_IS_LOADING_INFINITE, true)
      const response = await getOrderHistory(params)
      commit(SET_NEW_ORDERS, response.data.orders)
      commit(SET_CURSOR, response.data.paging.cursors.after)
      commit(SET_IS_LOADING_INFINITE, false)
    },
    async [GET_SUMMARY]({ getters, commit }) {
      commit(TOGGLE_IS_LOADING, true)
      const params = getters.requestParams
      const response = await getSummary(params)
      commit(TOGGLE_IS_LOADING, false)
      commit(SET_SUMMARY, response.data)
      trackRestaurantData(response.data)
    },
    async [GET_DAC7_SELLER_REPORT]({ commit }, id) {
      commit(SET_DAC7_SELLER_REPORT, null)
      commit(TOGGLE_IS_LOADING, true)
      const response = await getDac7SellerReport(id)
      commit(TOGGLE_IS_LOADING, false)
      commit(SET_DAC7_SELLER_REPORT, response.data)
    },
    async [GER_ORDER_DETAILS]({ commit }, id) {
      commit(SET_IS_LOADING_DETAILS, true)
      try {
        const response = await getOrderDetails(id)
        commit(SET_ORDER_DETAILS, response.data)
      } catch (err) {
        commit(SET_ORDER_DETAILS, null)
      } finally {
        commit(SET_IS_LOADING_DETAILS, false)
      }
    },
  },
})
